<template>
  <div class="d-flex flex-column flex-lg-row justify-content-center">
    <div class="w-100 w-lg-600px">
      <div class="card">
        <div class="card-body" v-if="dto.id">

          <h1
              class="card-title text-dark fw-bolder"
          >{{ $getLocalizedValue(dto.title, $i18n.locale) }}</h1
          >

          <div style="border-top: 1px solid #eff2f5" class="mt-1"></div>

          <article
              class="mt-2"
              v-html="$getLocalizedValue(dto.content, $i18n.locale)"
          ></article>

          <div
              style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <p class="card-title fw-bolder fs-7 mt-4" style="color: #424242">
              <i class="fa fa-user me-1 fs-5" style="color: #009ef7"></i
              >&nbsp;{{ dto.author }}
              &nbsp;&nbsp;
              <i class="fa fa-calendar-alt me-1 fs-5" style="color: #009ef7"></i
              >&nbsp;{{ decorateDate(dto.createdDate) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent} from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  data: function () {
    return {
      dto: {
        id: null,
      },
    };
  },
  async created() {
    await ApiService.get("api", `article/${this.$route.params.id}`).then(
      (response) => {
        this.dto = response.data;
        document.title = this.$getLocalizedValue(this.dto.title, this.$i18n.locale) + " - Gro Repository";
      }
    );
  },
  methods: {
    decorateDate(dt) {
      let lang = localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en";

      const formatter = new Intl.DateTimeFormat(lang, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      return formatter.format(new Date(dt));
    },
  },
});
</script>
